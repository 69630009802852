@import "src/main";

.MessageWrapper {
  display: flex;
  flex-direction: row;
}

.Left {
  justify-content: flex-start;
}

.Right {
  justify-content: flex-end;
}

.Message {
  display: inline-flex;
  flex-direction: column;
  border: 1px solid $card-border-color;
  border-radius: $card-border-radius;
  background-color: $message-background-color;
  padding: 0.5rem;
  width: 75%;
  margin-top: 1rem;
}

.Author {
  color: $primary;
  font-weight: bold;
}

.Body {
  display: inline-flex;
  flex-direction: row;
  align-items: flex-end;
}

.Content {
  flex-grow: 1;
}

.Time {
  margin-left: 0.25rem;
  color: $text-muted;
  font-style: italic;
  font-size: 0.75rem;
}