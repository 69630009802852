.Wrapper {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.Show {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}


.Content {
  width: 50%;
  margin: 0 auto;

  @media screen and (max-width: 1000px) {
    width: 90%;
  }
}

.Header {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;

  h5 {
    margin: 0;
  }
}