.Wrapper {
  position: fixed;
  top: 1rem;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.Form {
  margin: auto;
  width: 50%;
}