.FullPage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
  padding: 0.5rem;
  overflow-y: scroll;
}

.Card {
  display: inline;
  margin: 0 auto;
  width: 50%;

  @media screen and (max-width: 1000px) {
    width: 90%;
  }
}