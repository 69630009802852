.Chat {
  position: relative;
}

.Alert {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
}

.Body {
  height: calc(100vh - 8rem);
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2rem;
    background: rgb(255, 255, 255);
    background: linear-gradient(to bottom, #fff 0%, #fff 35%, transparent 100%);
    z-index: 1;
  }
}

.ScrollWrapper {
  height: calc(100% - 4rem);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.Scroll {
  position: relative;
  overflow-y: scroll;
  max-height: 100%;
  padding: 0 1rem;
}
