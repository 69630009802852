@import "Main.module";

.FullPage {
  position: relative;
}

.Card {
  height: 100%;
}

