$primary: rgb(13, 139, 192);
$secondary: rgb(105, 159, 180);
$text-primary: white;
$text-secondary: white;
$message-background-color: rgba(0, 0, 0, 0.05);

* {
  //outline: 1px solid red;
}

@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/bootstrap-icons/font/bootstrap-icons";

.header-secondary {
  background-color: $secondary;
  color: $text-secondary;
}

.btn-primary {
  color: $text-primary;

  &:hover {
    color: $text-primary;
  }
}

.btn-secondary {
  color: $text-secondary;

  &:hover {
    color: $text-secondary;
  }
}