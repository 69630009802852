.Loader {
  display: none;
  position: fixed;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.Loading {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.Spinner {
  display: block;
  margin: auto;
  width: 20rem;
  height: 20rem;
  border-radius: 10rem;
  border: 5px dashed white;
  animation: Spinner-spin 1s infinite linear;
}

@keyframes Spinner-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}